// extracted by mini-css-extract-plugin
export var backButton = "product-hero-module--back-button--0a24a";
export var backButtonDiv = "product-hero-module--back-button-div--8adc0";
export var col_1 = "product-hero-module--col_1--4a796";
export var col_2 = "product-hero-module--col_2--b0441";
export var container = "product-hero-module--container--b8237";
export var h2 = "product-hero-module--h2--9a2d8";
export var heroImage = "product-hero-module--hero-image--69406";
export var productImage = "product-hero-module--product-image--6cc2e";
export var product_img = "product-hero-module--product_img--9c083";
export var richTextTitle = "product-hero-module--richTextTitle--f20e0";
export var shop = "product-hero-module--shop--e49cc";
export var variantDropdown = "product-hero-module--variant-dropdown--847df";