import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import Seo from '../components/seo';
import { useLocale, useSiteQuery } from '../hooks';
import ProductHero from '../components/Product/ProductHero/ProductHero';
import VariantProductHero from '../components/Product/ProductHero/VariantProductHero';
import RelatedArticles from '../components/Shared/RelatedArticles/RelatedArticles';
import ArticleRelatedArticles from '../ArticlePage/ArticleRelatedArticles/ArticleRelatedArtciles';
import Schema from "../components/schema";
import RecipeSection from "../components/Shared/Generic/RecipeSection/RecipeSection";
import Articles from '../components/Shared/Generic/ArticlesSection/Articles';
import { getPathname } from "../utils/functions";
import SchemaUS from "../components/schemaus";
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';


const Products = ({ location, pageContext, data }) => {
  const locale = useLocale();
  const [currentIndex, setCurrentIndex] = useState(0);
  const {
    contentfulPageProducts: {
      slug,
      seoImage,
      seoTitle,
      seoDescription,
      createdAt,
      updatedAt,
      subTitle,
      longSubtitle,
      categoryTitle,
      title,
      cta,
      productDescription,
      ingredientsDescription,
      thumbnailImage,
      havingVariants,
      sizes,
      variants,
      packageSize,
      productId,
      relatedProducts,
      relatedArticles,
      canonicalSlug,
      imageGrid,
      sections,
      blackUgcScript
    },
    allContentfulPageProducts: {
      nodes: allLinks,
    },
  } = data;


  // useEffect(() => {
  //   const handleUserInteraction = () => {
  //     if (locale === 'en-US') {
  //       // Lazy load Bazaarvoice script only when needed
  //       const script = document.createElement("script");
  //       script.src = "https://apps.bazaarvoice.com/deployments/lipton-tea/main_site/production/en_US/bv.js";
  //       script.async = true;
  //       script.onload = () => {
  //         // Script loaded successfully, initialize Bazaarvoice
  //         // console.log('Bazaarvoice script loaded.');
  //       };
  //       script.onerror = (error) => {
  //         // Handle script loading errors
  //         // console.error('Error loading Bazaarvoice script:', error);
  //       };
  //       document.head.appendChild(script);
  //     }
  //   };
  
  //   window.addEventListener('click', handleUserInteraction);
  
  //   return () => {
  //     window.removeEventListener('click', handleUserInteraction);
  //   };
  // }, []);



  
  const shouldRenderRelatedArticles =
    !["es-PE", "en-CA", "fr-CA", "en-AE", "ar-AE"].includes(locale) && relatedArticles;

  const shouldRenderRecipeSection =
    ["en-CA", "tr-TR", "en-US", "fr"].includes(locale) && sections;

    // useEffect(
    //   () => {
    //   const embedCuralateScript = () => {
    //     const craluateScript = document.createElement("script");
    //     craluateScript.innerHTML = `
    //       var CRL8_SITENAME = 'lipton-hrz117';
    //       !function(){
    //         var e=window.crl8=window.crl8||{},n=!1,i=[];
    //         e.ready=function(e){
    //             n?e():i.push(e)
    //         },
    //         e.pixel=e.pixel||function(){
    //             e.pixel.q.push(arguments)
    //         },
    //         e.pixel.q=e.pixel.q||[];
    //         var t=window.document,
    //             o=t.createElement("script"),
    //             c=e.debug||-1!==t.location.search.indexOf("crl8-debug=true")?"js":"min.js";
    //         o.async=!0,
    //         o.src=t.location.protocol+"//edge.curalate.com/sites/"+CRL8_SITENAME+"/site/latest/site."+c,
    //         o.onload=function(){
    //             n=!0,
    //             i.forEach(function(e){
    //                 e()
    //             })
    //         };
    //         var r=t.getElementsByTagName("script")[0];
    //         r.parentNode.insertBefore(o,r.nextSibling)
    //     }();
    //     `;
    //     document.head.appendChild(craluateScript);
    //   };
    //   embedCuralateScript();
    // },
    // []); // Empty dependency array ensures the effect runs only once on mount

    // BV UGC carousel asked to remove on 01/07/24
    // const useCuralateScript = () => {
    //   const handleLoadRef = useRef();
      
    //   useEffect(() => {
    //     if (!blackUgcScript) return;
  
    //     const embedCuralateScript = () => {
    //       const CRL8_SITENAME = 'lipton-hrz117';
    //       const baseUrl = `https://edge.curalate.com/sites/${CRL8_SITENAME}/site/latest/`;
    //       const scriptUrl = `${baseUrl}site.min.js`;
          
    //       // Add preconnect for faster DNS resolution
    //       const preconnectLink = document.createElement('link');
    //       preconnectLink.rel = 'preconnect';
    //       preconnectLink.href = 'https://edge.curalate.com';
    //       document.head.appendChild(preconnectLink);
          
    //       // Load script
    //       const script = document.createElement('script');
    //       script.src = scriptUrl;
    //       script.defer = true; // Execute scripts in order
    //       script.onerror = () => console.error('Failed to load the Curalate script.');
    //       document.head.appendChild(script);
    //     };
    
    //     handleLoadRef.current = () => {
    //       embedCuralateScript();
    //       window.removeEventListener('load', handleLoadRef.current);
    //     };
    
    //     if (document.readyState === 'complete') {
    //       // Document already loaded, embed the script
    //       embedCuralateScript();
    //     } else {
    //       window.addEventListener('load', handleLoadRef.current);
    //     }
    
    //     // Cleanup function to remove event listener
    //     return () => {
    //       window.removeEventListener('load', handleLoadRef.current);
    //     };
    //   }, [blackUgcScript]);
    // };
    
    // useCuralateScript();


  return (
    <>
      <Seo
        title={seoTitle}
        lang={locale}
        productImage={seoImage}
        description={seoDescription?.seoDescription}
        hrefLangs={allLinks}
        slick={false}
      />
      {/* <Schema
        type="Product"
        title={title}
        updated={updatedAt}
        created={createdAt}
        url={location.href}
        image={thumbnailImage.fluid.src}
        desc={categoryTitle}
        link={cta && cta[0].slug}
        slug={getPathname(pageContext.node_locale, slug)}
      /> */}

      {/* {locale=='en-US' ?
          <script src="https://apps.bazaarvoice.com/deployments/lipton-tea/main_site/production/en_US/bv.js" id="script-bv" type="text/javascript" />
    : null } */}

      {locale=='en-US' ?
        <SchemaUS
        schema={{
        "@context": "http://schema.org/",
        "@type": "Product",
        "@id" : `https://www.lipton.com${getPathname(pageContext.node_locale,slug)}`,
        'name': title,
        'image' : thumbnailImage.fluid.src,
        'description': longSubtitle ? longSubtitle.longSubtitle : categoryTitle,
        'sku': productId,
        'brand': 'Lipton',
        //'productID': productId,
      }}
      />
      :
        <Schema
        type="Product"
        title={title}
        updated={updatedAt}
        created={createdAt}
        url={location.href}
        image={thumbnailImage.fluid.src}
        desc={categoryTitle}
        link={cta && cta[0].slug}
        slug={getPathname(pageContext.node_locale, slug)}
      />
      }
      
      {havingVariants ? (
        <VariantProductHero
          categoryTitle={categoryTitle}
          cta={cta}
          relatedProducts={relatedProducts}
          productId={productId}
          productDescription={productDescription}
          ingredientsDescription={ingredientsDescription}
          sizes={sizes}
          variants={variants[currentIndex]}
          setter={setCurrentIndex}
          title={title}
        />
      ) : (
        <ProductHero
          heroImage={thumbnailImage}
          categoryTitle={categoryTitle}
          title={title}
          subTitle={subTitle}
          longSubtitle={longSubtitle}
          packageSize={packageSize}
          productId={productId}
          cta={cta}
          relatedProducts={relatedProducts}
          productDescription={productDescription}
          ingredientsDescription={ingredientsDescription}
          imageGrid={imageGrid}
        />
      )}

      {shouldRenderRelatedArticles && (
        <ArticleRelatedArticles articles={relatedArticles} articlesIcon={null} align={false} />
      )}

      {shouldRenderRecipeSection && <RecipeSection articles={sections[0]} />}
      {/* {blackUgcScript && <div data-crl8-container-id="gallery-V4ybitK2"></div>} */}
    </>
  );
};


export const ProductsQuery = graphql`
    query MyQuery($id:String!, $node_locale: String!, $contentful_id: String!){
        contentfulPageProducts(id:{eq:$id}) {
            slug
            seoTitle
            subTitle
            categoryTitle
            title
            richTextTitle{
              raw
            }
            canonicalSlug
            blackUgcScript
            longSubtitle{
                longSubtitle
            }
            seoDescription{
                seoDescription
            }
            createdAt(locale: $node_locale, formatString: "MMMM DD, YYYY")
            updatedAt(formatString: "MMMM DD, YYYY")
            cta {
                title
                slug
            }
                     
            productDescription {
                raw
            }
            seoImage:thumbnailImage {
                title
                fluid (quality:100){
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            thumbnailImage {
                title
                fluid (maxWidth: 800, quality:70){
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            havingVariants
            sizes {
            title
            sku
            }
            variants {
                title
                 richTextTitle{
                  raw
                }
                description {
                  description
                }
                variantThumbnailImage {
                    title
                    fluid (maxWidth: 800, quality:70){
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                variantImageGridContainer {
                  productImages {
                    fluid (quality:70){
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                  }
                }
              }
            imageGrid {
                ... on ContentfulImageGridContainer {
                    id
                    navigation
                    productImages {
                        fluid (quality:70){
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
            packageSize
            productId
            sections {
                ... on ContentfulComponentFeaturedItems {
                    title
                    type
                    __typename
                    icon {
                        fluid {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    sectionThumbnail {
                        ... on ContentfulPageArticle {
                          __typename
                          title
                          thumbnailImage {
                            fluid {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                          }
                          slug
                          articlePageIcon {
                            fluid {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                          }
                        }
                        ... on ContentfulPageRecipe {
                          __typename
                          title
                          heroImage {
                            fluid {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                            title
                          }
                          slug
                          recipeIcon {
                            fluid {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                          }
                        }
                      }
                    }
                  }
                
            relatedProducts {
                id
                slug
                title
                thumbnailImage {
                    title
                    fluid {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
            relatedArticles {
                title
                slug
                thumbnailCategoryTitle
                thumbnailImage {
                    title
                    fluid {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                articlePageIcon {
                    title
                    fluid {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
        }
        allContentfulPageProducts(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}, slug: {ne: null}}){
            nodes {
                slug
                node_locale
            }
        }
    }
  
`

export default Products