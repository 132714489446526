import React, { useEffect, useState } from "react"
import ProductForm from "../ProductForm/ProductForm"
import Img from "gatsby-image"
import * as styles from "./product-hero.module.scss"
import { useLocale, useDictionaryQuery } from "../../../hooks"
import { Link, navigate } from "gatsby"
import ReactImageMagnify from "@milosmladenovicwork/react-image-magnify"
import { useWindowSize } from "react-use"
import ImageGridContainer from "../ImageGridContainer/ImageGridContainer"
import loadable from "@loadable/component"
import ProductDescription from "../ProductDescription/ProductDescription"
import IngredientsDescription from "../IngridentsDescription/IngridentsDescription"
import ProductReviews from "../Reviews/ProductReviews"
import QuestionProduct from "../QuestionAndAnswer/QuesProduct"
import { useSearchQuery } from "../../../hooks"
import { RichText } from "../../../components/Layout/RichText/RichText"

const ProductSlider = loadable(() => import("../ProductSlider/ProductSlider"))

const VariantProductHero = ({
  categoryTitle,
  title,
  subTitle,
  longSubtitle,
  packageSize,
  productId,
  cta,
  relatedProducts,
  productDescription,
  ingredientsDescription,
  setter,
  sizes,
  variants,
}) => {
  console.log("variants",variants)
  const { width: windowWidth } = useWindowSize()
  const { ratingsandReview, productDoNotDisplayButton } = useSearchQuery()
  const { backButton } = useDictionaryQuery()
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--swiper-navigation-color",
      "#000"
    )
    document.documentElement.style.setProperty(
      "--swiper-pagination-color",
      "#000"
    )
  }, [])

  const productMultiThumbnail = variants.variantImageGridContainer
  const [selectImage, setSelectImage] = useState(0)
  const lang = useLocale()

  const { productsFullCharacteristics, productPageOtherProducts } =
    useDictionaryQuery()


    // useEffect(() => {
    //   const handleUserInteraction = () => {
    //     if (lang === 'en-US') {
    //       // Lazy load Bazaarvoice script only when needed
    //       const script = document.createElement("script");
    //       script.src = "https://apps.bazaarvoice.com/deployments/lipton-tea/main_site/production/en_US/bv.js";
    //       script.async = true;
    //       script.onload = () => {
    //         // Script loaded successfully, initialize Bazaarvoice
    //         // console.log('Bazaarvoice script loaded.');
    //       };
    //       script.onerror = (error) => {
    //         // Handle script loading errors
    //         // console.error('Error loading Bazaarvoice script:', error);
    //       };
    //       document.head.appendChild(script);
    //     }
    //   }
    //   window.addEventListener('click', handleUserInteraction);
  
    //   return () => {
    //     window.removeEventListener('click', handleUserInteraction);
    //   };
    // }, []);
  
    


    useEffect(() => {
      const script = document.createElement("script");
      script.innerHTML = `
        if (typeof PriceSpider !== 'undefined' && PriceSpider.rebind) {
          PriceSpider.rebind();
        }
      `;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, []);

  const [idx, setIdx] = useState(0)
  useEffect(() => {
    document
      .getElementsByClassName("list")
      [idx].setAttribute("selected", "selected")
  })
  


  const VariantDropdown = () => {
    return (
      <>
        <label className="sr-only" htmlFor="sizevariant">
          Select size
        </label>
        <select
          className={styles.variantDropdown}
          id="sizevariant"
          onChange={e => {
            const currentIndex = e.target.selectedIndex
            setter(currentIndex)
            setIdx(currentIndex)
          }}
        >
          {sizes.map((item: any, id: number) => {
            const { title, slug } = item
            return (
              <option className="list" key={title} value={`${title}`}>
                {title}
              </option>
            )
          })}
        </select>
      </>
    )
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.col_1}>
          <div
            onClick={() => {
              navigate(-1)
            }}
            className={`${styles.backButton} ${styles.backButtonDiv}`}
          >
            <span className={`styles.button`}>{backButton}</span>
          </div>
          <div>
            {windowWidth > 1350 ? (
              <div className={styles.heroImage}>
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: title ? title : null,
                      isFluidWidth: true,
                      src: (productMultiThumbnail
                        ? productMultiThumbnail[0].productImages[selectImage]
                        : variants.variantThumbnailImage
                      )?.fluid?.src,
                    },
                    largeImage: {
                      src: (productMultiThumbnail
                        ? productMultiThumbnail[0].productImages[selectImage]
                        : variants.variantThumbnailImage
                      )?.fluid?.src,
                      width: 1000,
                      height: 1200,
                    },
                  }}
                />
              </div>
            ) : (
              windowWidth > 1024 && (
                <div className={styles.heroImage}>
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: title ? title : null,
                        isFluidWidth: true,
                        src: (productMultiThumbnail
                          ? productMultiThumbnail[0].productImages[selectImage]
                          : variants.variantThumbnailImage
                        )?.fluid?.src,
                      },
                      largeImage: {
                        src: (productMultiThumbnail
                          ? productMultiThumbnail[0].productImages[selectImage]
                          : variants.variantThumbnailImage
                        )?.fluid?.src,
                        width: 700,
                        height: 800,
                      },
                    }}
                  />
                </div>
              )
            )}
          </div>
          <div>
            <div className={styles.productImage}>
              <Img
                fluid={
                  (productMultiThumbnail
                    ? productMultiThumbnail[0].productImages[selectImage]
                    : variants.variantThumbnailImage
                  )?.fluid
                }
                alt={variants.variantThumbnailImage.title}
                loading="lazy"
                fadeIn={false}
              />
            </div>
          </div>
          {productMultiThumbnail && (
            <ImageGridContainer
              productImages={productMultiThumbnail[0].productImages}
              setSelectImageFunc={setSelectImage}
              navigation={undefined}
            />
          )}
        </div>

        <div className={`${styles.col_2} spacer__01`}>
          <p>{categoryTitle}</p>
          {!variants.richTextTitle?<h1 className={styles.h2}>{variants.title}</h1>:<div className={styles.richTextTitle}><RichText content={variants.richTextTitle} /></div>}
          {variants.description && <p>{variants.description.description}</p>}
          {productId && productDoNotDisplayButton ? null : (
            <div
              className={`${styles.shop} ps-widget`}
              ps-sku={productId}
            ></div>
          )}
          <div
            data-bv-show="rating_summary"
            data-bv-product-id={productId}
          ></div>
          {lang !== "es-PE" ? (
            cta && cta[0] && cta[0].slug ? (
              <Link target="_blank" to={`${cta[0].slug}`}>
                <button className="btn-primary"> {cta && cta[0].title}</button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div>
            <VariantDropdown />
          </div>

          <div></div>
          <ProductSlider
            productArray={relatedProducts}
            title={productPageOtherProducts}
          />
          <div className={`styles.description`}>
            <ProductDescription
              content={productDescription}
              title={productsFullCharacteristics}
            />

            {ingredientsDescription && (
              <IngredientsDescription
                content={ingredientsDescription}
                title="Ingredienser"
              />
            )}
            {ratingsandReview && (
              <ProductReviews
                content={ingredientsDescription}
                title={ratingsandReview}
                productId={productId}
              />
            )}
            {QuestionProduct && (
            <QuestionProduct
              title="Questions and Answers"
              productId={productId}
            />
          )} 
          </div>
        </div>
      </div>
    </div>
  )
}

export default VariantProductHero
